/* ----------------------GENERAL STYLES------------------------- */

* {
    padding: 0;
    margin: 0;
	box-sizing: border-box;
}

li {
    list-style-type: none;
}

h1 {
    font-family: 'Fira Code', monospace;
    font-weight: 500;
    font-size: 18px;
    padding: 10px 0 10px 0;
}

.secondary-container {
    margin-top: 50px;
}

.title {
    font-family: 'Fira Code', monospace;
    margin-bottom: 5vh;
}

.title::after {
    content:"";
    display:block;
    width: 2em;
    border-bottom: 0.2em solid #5bccf6;
    margin-top: 0.5em;
}

.static {
  position:absolute;
  /* background: white; */
}

.static:hover {
  opacity:0;
}


 /* NAVIGATION MENU */

.navbar {
    background-color: white;
    display: flex;
    padding: 20px 40px;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1;
	text-transform: uppercase;
    cursor: pointer;
}

#nav-menu-elements {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

#nav-menu-elements a {
    position: relative;
    color: #17252A;
    text-decoration: none;
    padding: 10px 20px 0 20px;
    font-family: 'Fira Code', monospace;
    font-size: 18px;
    font-weight: 500;
    
}

/* Hover effect */

#nav-menu-elements a:hover, 
#nav-menu-elements a.active {
    color: #979d9e;
    transition: 0.3s;
}

#mobile-nav {
    display: none;
}

#mobile-nav i {
    color: #17252A;
}

.homepage {
    height: 400px;
    width: auto;
    background-color: #fcde67;
    position: relative;
    font-family: 'Fira Code', monospace;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.homepage-art {
    position: relative;
    object-fit: contain;
    width: 100%;
    object-position: 40em 1em;
}

.homepage-title {
    font-family: 'Fira Code', monospace;
    font-size: 40px;
    color: #17252A;
    padding-bottom: 10px;
}

.homepage-subtitle {
    font-family: 'Fira Code', monospace;
    font-size: 30px;
    color: #17252A;
    padding-bottom: 10px;
}

.homepage-description {
    font-family: 'Fira Code', monospace;
    font-size: 24px;
    color: #17252A;
}

span {
    margin-left: 15%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.about {
    height: auto;
    margin-bottom: 5vh;
    font-size: 16px;
}

.about-me {
    line-height: 1.5;
    flex: 2;
}


.bio {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    color: #17252A;
}

.profile-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 5vw 0 0 0;
}

.profile-image {
    max-width: 80%;
    display: block;
    border: 10px solid #fcde67;
    margin-bottom: 20px;
}

.skills-edu-section {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    color: #17252A;
}


.skills {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}


.skills-list li {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #5bccf6;
    border-radius: 8px;
    color: white;
}


.skills-list {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Fira Code', monospace;
}

.edu-list li {
    margin-bottom: 10px;
    line-height: 1.8;
}

.edu-list li::before {
    content: "▹ ";
}

.main-projects-container {
    margin-top: 50px;
}

.projects {
    color: #17252A;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.project-container {
    background-color: #e2e9ec;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 0 20px;
}

.project-description {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
}

.project-tools {
    font-family: 'Fira Code', monospace;
    font-size: 16px;
}

.project-name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.project-links img {
    margin: 10px;
}

.contact {
    height: auto;
    font-family: 'Inter', sans-serif;
    color: #17252A;
    text-align: center;
}

.contact-btn {
    font-family: 'Fira Code', monospace;
    font-size: 20px;
    color: #17252A;
    text-decoration: none;
    margin: 50px;
    padding: 15px;
    border-color: #17252A;
    border-radius: 10px;
    background-color: #fcde67;
    cursor: pointer;
}

.contact-collab {
    font-size: 24px;
    font-weight: 600;
    margin-top: 50px;

}

.contact-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 100px 0;
    cursor: pointer;
}

.github-icon {
    margin-right: 20px;
}

.contact {
    font-size: 20px;
}

.footer {
    background-color: #5bccf6;
    padding: 20px 0 20px 0;
    font-family: 'Fira Code', monospace;
    color: white;
    text-align: center;
    font-size: 16px;
}


/* ----------------------MOBILE STYLES------------------------- */

@media screen and (max-width: 830px) {

    .secondary-container {
        margin: 0 10px 0 10px;
    }

    h1 {
        margin: 20px 0 20px 0;
    }

    #nav-menu-elements {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 65px;
        right: 0px;
        width: 200px;
        height: 100vh;
        background-color: white;
        padding: 30px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 1;
    }

    #nav-menu-elements.active {
        right: -300px;
    }

    #nav-menu-elements a {
        margin-bottom: 15px;
    }

    #mobile-nav {
        display: block;
    }

    #mobile-nav i {
        font-size: 24px;
    }

    .homepage-art {
        object-fit: cover;
        object-position: 8em 1em;
    }

    .homepage-title {
        font-size: 30px;
    }
    
    .homepage-subtitle {
        font-size: 28px;
    }
    
    .homepage-description {
        font-size: 22px;
    }

    .about {
        margin: 0 4vw;
    }

    .about-me {
        margin-bottom: 5vh;
    }

    .skills-list {
        margin-bottom: 5vh;
    }

    .main-projects-container {
        margin-bottom: 50px;
    }

    .projects {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .projects-title {
        margin-left: 4vw;
    }

    .project-item {
        height: 350px;
        width: 350px;
        position: relative;
        margin: 20px;
    }
    
    .project-video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .main-projects-container {
        margin: 5;
    }

    .project-container {
        width: 380px;
        margin-bottom: 10px;
    }

    .project-description {
        margin: 10px;
    }

    .project-links img {
        margin: 10px;
    }

    .contact {
        margin: 0 4vw;
    }

    .footer {
        padding: 20px;
    }

    .github-icon {
        width: 40px;
    }
    
    .linkedin-icon {
        width: 30px;
    }
    
}


/* ----------------------DESKTOP STYLES------------------------- */

@media (min-width: 830px) {

    .title {
        font-size: 22px;
    }

    .about-title {
        margin: 2vw 14vw 0 14vw;
    }

    .about-me {
        font-size: 18px;
    }

    .bio {
        margin: 2vw 14vw 0 14vw;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .profile-image-container {
        margin: 0;
    }

    .profile-image {
        max-width: 80%;
        display: block;
        border: 10px solid #5bccf6;
        margin: 0;
    }


    .skills-edu-section {
        margin: 0 14vw;
        display: flex;
        flex-direction: row;
    }

    .edu-list li {
        margin-bottom: 20px;
        line-height: normal;
        font-size: 18px;
    }

    .skills {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
    }
    
    .education {
        flex: 1;
        margin-left: 30px;
        font-family: 'Inter', sans-serif;
    }
    
    .skills-list li {
        font-family: 'Fira Code', monospace;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 15px;
        background-color: #5bccf6;
        border-radius: 8px;
        color: white;
    }

    #projects.projects {
        margin: 0 50px;
    }

    .projects-title {
        margin-left: 14vw;
        margin-bottom: 5vh;
    }


    .project-item {
        height: 400px;
        width: 400px;
        position: relative;
        margin-top: 20px;
    }
    
    .project-video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .project-container {
        width: 420px;
        max-height: 600px;
        margin: 20px;
    }

    .project-info-container {
        align-content: center;
    }

    .project-name {
        margin: 10px;
    }

    .project-description {
        margin: 0 10px 10px 10px;
    }

    .project-tools {
        margin: 0 10px 0 10px;
    }

    .project-links img {
        margin: 10px;
    }

    .github-icon {
        width: 45px;
    }
    
    .linkedin-icon {
        width: 35px;
    }
}

/* ----------------------LARGE SCREENS------------------------- */

@media screen and (min-width: 1900px) {
    .about-me {
        font-size: 20px;
    }

    .title {
        font-size: 28px;
    }

    .skills-list {
        font-size: 20px;
    }

    .edu-list li {
        font-size: 20px;
    }

    .contact-collab {
        font-size: 28px;
    }

    .contact-btn {
        font-size: 24px;
        cursor: pointer;
    }

    #contact.contact {
        font-size: 26px;
    }

    .github-icon {
        width: 60px;
    }

    .linkedin-icon {
        width: 43px;
    }
}

.favicon {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 500px;
    height: 500px;
    font-family: 'Fira Code', monospace;
    font-size: 500px;
    background-color: #5bccf6;
    color:white;
    padding: 20px;
    margin: 50px;
    text-transform: lowercase;
    border-radius: 50px;

}